import * as React from 'react';

import { useSignUp } from './SignUpContext';
import { Button, Form } from 'react-bootstrap';
import { useScreenInfo } from '../../utils';
import { useTimeout } from 'usehooks-ts';

export const Step4: React.FunctionComponent = () => {
  const { state, actions } = useSignUp();
  const screen = useScreenInfo();
  const [selectedSource, setSelectedSource] = React.useState('');

  useTimeout(() => {
    localStorage.removeItem('sign-up');
  }, 1000);

  if (state.currentStep !== 4) {
    return <></>;
  }

  const sourceOptions = [
    'I received a letter',
    'Press or news article',
    'Facebook',
    'Google or other search engine',
    'Instagram',
    'From a friend or family member',
    'Podcast/Radio',
    'Billboard',
    'YouTube',
    'Other',
  ];

  return (
    <>
      <div className="bg-light-blue p-3" style={{ borderRadius: 24 }}>
        <h4 className="mt-1">Engagement documents will be emailed to you shortly. 🎉</h4>
        <div className="fs-6 ms-3">Once you have signed your engagement documents, we will file your tax protest and begin preparing your case.</div>

        <Button href="/" className={`mt-4 ${screen.isPhone ? 'd-block' : ''}`}>
          Return to Home
        </Button>
      </div>

      {state.hideSource && (
        <div className="bg-light-orange border border-3 border-primary p-3 mt-3" style={{ borderRadius: 24 }}>
          <h2 className="mb-0">Thank You!! 🥳</h2>
        </div>
      )}

      {!state.hideSource && (
        <div className="bg-light-orange border border-3 border-primary p-3 mt-3" style={{ borderRadius: 24 }}>
          <h2 className="mb-4 text-navy">Before you go... How did you first hear about us?</h2>
          <div className="ms-1">
            {sourceOptions.map((value, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                name="source-option"
                className="checkbox-xl"
                id={`source-${index}`}
                label={value}
                checked={selectedSource === value}
                onChange={() => (selectedSource === value ? setSelectedSource('') : setSelectedSource(value))}
              />
            ))}
          </div>
          <Button className={`mt-2 ${screen.isPhone ? 'd-block' : ''}`} disabled={selectedSource === ''} onClick={() => actions.saveSource(selectedSource)}>
            Submit
          </Button>
        </div>
      )}
    </>
  );
};
